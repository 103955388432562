import React from 'react'
import logo from '../assets/cronoverde.png';
import logotel from '../assets/crono_tel.png';
import novaLogo from '../assets/crono_novo.png';



const Cabecalho = () => {
    return (
        <div style={{marginTop: '5px', marginBottom: '5px'}}><img src={novaLogo} alt="Logo" /></div>
    )
}

export default Cabecalho;