import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faRankingStar } from '@fortawesome/free-solid-svg-icons'
import { useNavigate , useLocation } from 'react-router-dom';
import CardAtleta from '../../components/cardAtleta';


const Resultados =  () => {
    const history = useNavigate ();

    let location =useLocation();

    const[resultados, setResultados] = useState([]);
    const[resultadoOriginal, setResultadoOriginal] = useState([]);
    const[textoBusca, setTextoBusca] = useState("");
    const[evento, setEvento] = useState({});

   

    async function getResultados() {

        let evt = location.state;
        setEvento(evt);
      
      if(evento) {
        //const response =  await api.get('/api/resultado/1/10 KM/M');
        const response =  await api.get(`/api/resultado/${evt.codigo}/${evt.modalidade}/${evt.genero}`);
        //let aa = `/api/resultado/${evento.codigo}/${evento.modalidade}/${evento.genero}`;
        //const response =  await api.get(aa);
        //const response =  await api.get(`/api/resultado/${evento.codigo}/${evento.modalidade}/${evento.genero}`);
           
            setResultadoOriginal(response.data.dados);
            setResultados(response.data.dados);
      }
      //const response =  await api.get(`/api/resultado/${evento.codigo}/${evento.modalidade}/${evento.genero}`);
      
     
     
      //setResultados(resultadoFiltrado);
  }

  function filtraResultado () {
    const resultadoFiltrado = resultadoOriginal.filter(function (res) {
        //return res.modalidade == "5 KM";
       //return (res.atleta.toLowerCase().includes(textoBusca.toLowerCase()) || res.modalidade.toLowerCase().includes(textoBusca.toLowerCase()));
       return (res.atleta.toLowerCase().includes(textoBusca.toLowerCase()));
    }
        );
 
  //setResultados(response.data.dados);
  setResultados(resultadoFiltrado);
  }
  
  useEffect(() => {
    if(location.state) {
       
        //console.log("evento = ", evt);
        getResultados();
    }
    else{
        console.log("Location", JSON.stringify(location));
    }
   
  }, []);

  useEffect(() => {
    filtraResultado ();
  }, [textoBusca]);
  

    return (
       
        <> 
        <div className="row mb-2">
            <h2 className="themeFontColor text-center">
               Resultados - {evento.descricao}
            </h2>
            <h3>
                Data: {evento.data} <br/>
                Modalidade: {evento.modalidade} <br/>
                Gênero: {evento.genero == 'M' ? "Masculino" : "Feminino"  }
            </h3>
           
            <input type="text" value={textoBusca} placeholder="Busca" onChange={(e) => setTextoBusca(e.target.value)}></input><br />
           
        </div>
       
        {evento && resultados.map ((resultadoAtleta) => (
                <CardAtleta resultado={resultadoAtleta}/>
              )
  
              )}
       
    </>
      );
}

export default Resultados;