import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faRankingStar, faPersonBiking, faPersonRunning, faUsersRectangle, faFlagCheckered, faClock} from '@fortawesome/free-solid-svg-icons'

import { Container, Form, Card, Row, Col } from 'react-bootstrap';
<FontAwesomeIcon icon="fa-regular " />
const Home =  () => {
  let navigate = useNavigate();

  function openEventDetails (evento) {

    localStorage.setItem('@meutempo/codigoEvento', evento);
    navigate('/evento', {state: {codigo: evento} })

    
  }

  const[err, setErr] = useState([]);

  const[eventos, setEventos] = useState([]);

  async function getEventos() {
    try {

        const response =  await api.get('/api/evento');
        //console.log("EVENTOS = ", JSON.stringify(response));

    
        setEventos(response.data.dados);
       
    } catch(err) {
        setErr(err);
    }
    
}

useEffect(() => {
  getEventos();
}, []);

    return (
   
      <> 
        <div className="w-100" style={{
       width: "90%",
       margin: "0 auto",
       border: "0px solid #ccc",
       borderRadius: "10px",
       padding: "20px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       marginTop: 0
      }}>
     
          <Container>
          <Row style={{ backgroundColor: "#cfffe5", borderRadius: "10px", color:'black' }}>
          <Col md={12}>
          <h1 style={{ backgroundColor: "#cfffe5", textAlign: "center", margin: "10px 0", fontSize: "24px", fontWeight:'bolder' }}>
             Eventos em andamento
            </h1>
          </Col>
        
        </Row>
       
    </Container>
          
            </div>

            <div className="w-80"  style={{marginLeft : 5, marginRight : 5}}>
            {eventos  && eventos.map ((evt) => (
               
                  <Card className="h-auto text-dark" style={{marginTop : 8}}>
                    <Row>
                    <Col xs={12} className="d-flex align-items-center justify-content-center">
                      <div className="rounded mx-1 d-flex align-items-center justify-content-center" style={{height: '100%', width: '100%', backgroundColor: '#ffd87d', margin:'5px ' }} onClick={() => openEventDetails(evt.codigo)}>
                        <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>
                              {
                                (evt.tipoEvento == 'MTB' || evt.tipoEvento == 'MTBPorVolta')  &&        
                                <FontAwesomeIcon icon={faPersonBiking}  style={{color:"#6DB65B"}}/>
                              }
                              {
                                (evt.tipoEvento == 'Corrida' || evt.tipoEvento == 'CorridaPorVolta') &&        
                                <FontAwesomeIcon icon={faPersonRunning}  style={{color:"#6DB65B"}}/>
                              }&nbsp;&nbsp;
                            {evt.descricao}
                             
                            
                            <br/>
                            {(new Date(evt.data)).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}<br/>
                            <button type="button" class="btn btn-success" style={{margin: 7}}>Acessar Resultado</button>
        
                        </p>
                        
                      
                      </div>
                     
                    </Col>
                   
                    </Row>
              </Card>
           
              )
  
              )}
            {eventos.length === 0 &&
                        <Card className="h-auto text-dark">
                        <Row>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                          <div className="rounded mx-1 d-flex align-items-center justify-content-center" style={{height: '100%', width: '100%', backgroundColor: '#ffd87d', margin:'5px ' }}>
                            <p style={{fontSize: '0.8em', fontWeight: 'bold'}}>
                               Nenhum evento encontrado
                            </p>
                            
                          
                          </div>
                         
                        </Col>
                       
                        </Row>
                  </Card>
                    }
            </div>
             

     
  </>
      );
}

export default Home;