import './cardAtleta.css';
const CardAtleta = ({resultado}) => {
    return (
        <div className='flex-container flex-container-style fixed-height'>
            <div className='flex-item'>Geral: {resultado.colocacao_geral} <br/> Faixa: {resultado.colocacao_faixa}</div>
          
            <div className='flex-item'>
               Número: {resultado.numero_atleta}  - {resultado.atleta}-  {resultado.tempo}
            </div >
            <div className='flex-item'></div>
            
            <hr></hr>
        </div>
    )
}

export default CardAtleta;