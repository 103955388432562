import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons'

const Rodape = () => {
    return (
        <div><a style={{textDecoration: 'none', color:'black', fontSize:"12px"}} href="https://linktr.ee/jeancarlomendes" target="_self" rel="noreferrer"> 
        <FontAwesomeIcon icon={faLaptopCode}  style={{color:"blue"}}/>&nbsp;
        Design by jncrlmnds - v1.1
       
        </a>

        </div>
    )
}

export default Rodape;