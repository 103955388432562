import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";

import Home from "./pages/home";
import Evento from "./pages/evento";
import Resultados from "./pages/resultados";

import Cabecalho from "./components/cabecalho";

const Rotas = () => {
   return(
    
       <Routes>
           <Route element = { <Home /> }  path="/" exact />
           <Route element = { <Evento /> }  path="/evento" />
           <Route element = { <Resultados />}  path="/resultados/:id" />
           <Route element = { <Resultados />}  path="/resultados" />
           <Route path="*" element={<NoMatch />} />
       </Routes> 
    
   )
}
function NoMatch() {
    return (
      <div>
        <h2>404</h2>
        <p>
         
        </p>
      </div>
    );
  }
export default Rotas;