import './App.css';
import {
  BrowserRouter
} from "react-router-dom";
import Cabecalho from "./components/cabecalho";
import Rodape from "./components/rodape";
import Rotas from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {
  return (
    <BrowserRouter> 
    <div className='App'>
    <Cabecalho/><header className="App-header">
      <Rotas />
      </header>
    <Rodape />
    </div>
    </BrowserRouter> 
  );
}
