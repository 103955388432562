import React, { useEffect, useState, Image } from 'react';

import api from '../../services/api';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faRepeat } from '@fortawesome/free-solid-svg-icons'
import { faUsersRectangle, faFlagCheckered, faClock, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'



import {faRankingStar, faHome } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@iconify/react';

import { Container, Form, Card, Row, Col } from 'react-bootstrap';

import CardAtleta from '../../components/cardAtleta';


const Evento =  () => {

  const history = useNavigate ();
  let navigate = useNavigate();

  let location =useLocation();

  


  const[codEvento, setCodEvento] = useState(localStorage.getItem('@meutempo/codigoEvento'));
  const[nomeEvento, setNomeEvento] = useState("");
  const[tipoEvento, setTipoEvento] = useState("");
  const[dataHoraUltimaAtualizacao, setDataHoraUltimaAtualizacao] = useState("");
  const[dataEvento, setDataEvento] = useState("");
  const[codModalidade, setCodModalidade] = useState("");
  const[codGenero, setCodGenero] = useState("");
  const[eventos, setEventos] = useState([]);
  const[evento, setEvento] = useState([]);
  const[exibeCamposFiltro, setExibeCamposFiltro] = useState(false);


  const[resultados, setResultados] = useState([]);
  const[resultadoCompleto, setResultadoCompleto] = useState([]);
  const[textoBuscaNome, setTextoBuscaNome] = useState("");
  const[textoBuscaNumero, setTextoBuscaNumero] = useState("");

    function goHome () {
   
      navigate('/',)
  
      
    }

    let handleNumeroChange = (e) => {
      const numero = e.target.value.replace(/\D/g, '');
      setTextoBuscaNumero(numero);
    }
    
  let handleModalidadeChange = (e) => {
    let codigoEvento = '';

    let dados = e.target.value.split('|');
   


    if(dados.length>1) {
      setCodModalidade(dados[1])
      setCodGenero (dados[2]);
      setExibeCamposFiltro(true);
      
      
    }
    else {
      
      setResultados(null);
      setCodModalidade(null);
      setExibeCamposFiltro(false);
     
    }
   
  }

  function filtraResultado () {

    let resultadoFiltradoPorNumero = resultadoCompleto;
    let resultadoFiltradoPorNome = resultadoCompleto;
   
   
      resultadoFiltradoPorNome = resultadoCompleto.filter(function (res) {
        
       return (res.atleta.toLowerCase().includes(textoBuscaNome.toLowerCase()));
        }
        );
    
  
      resultadoFiltradoPorNumero = resultadoFiltradoPorNome.filter(function (res) {
      
       return (res.numero_atleta.toString().toLowerCase().includes(textoBuscaNumero.toLowerCase()));
        }
        );
  
   
    const resultadoFiltrado =[...resultadoFiltradoPorNome,...resultadoFiltradoPorNumero];
 
 

  setResultados(resultadoFiltradoPorNumero);
  }
  async function getResultadoModalidade() {
    
    if(!localStorage.getItem('@meutempo/codigoEvento')) goHome();
    let codigoE;
      codigoE = await localStorage.getItem('@meutempo/codigoEvento');

    if(codModalidade) {
     // console.log("Modalidade ", codModalidade);
    // let evt = location.state;
     //let evt = codEvento
      let endP = "/api/resultado/" + codigoE + '/' + codModalidade + '/' + codGenero;
     // alert('endP = ' + endP)
      //setEvento(evt);
  
      
     
      const response =  await api.get(endP);
  
     
      setResultadoCompleto(response.data.dados);
      setResultados(response.data.dados);
      setExibeCamposFiltro(true);
    }
    else{
      setResultadoCompleto([]);
      setResultados([]);
      setExibeCamposFiltro(false);
    }
}

  async function updateAll() {
    await  getModalidades();
    await  getResultadoModalidade();
  }

  async function getModalidades() {


    let codigoE; 
     codigoE= await localStorage.getItem('@meutempo/codigoEvento');
    if(!codigoE) goHome();
    let endP = "/api/evento/" + codigoE;
   
    const response =  await api.get(endP);
  

    setEventos(response.data.dados);
    //console.log("DADOS ++++++ ", JSON.stringify(response.data.dados));
    
    if(response.data.dados.length>0){
      setEvento(response.data.dados[0]);
      setNomeEvento(response.data.dados[0].descricao);
      setTipoEvento(response.data.dados[0].tipoEvento);
      setDataEvento(response.data.dados[0].data);
      setDataHoraUltimaAtualizacao(response.data.dados[0].dataAtualizacao);
    }
    else
    {
      setNomeEvento("Evento não encontrado");
    }
}

useEffect(() => {
  filtraResultado ();
}, [textoBuscaNome, textoBuscaNumero]);



 useEffect(() => {
  //let codigoEvento = location.state.codigo;
  //let codigoEvento =   localStorage.getItem('@meutempo/codigoEvento');
  
    //setCodEvento(codigoEvento);
    if(!localStorage.getItem('@meutempo/codigoEvento')) goHome();
   //alert("Codigo " + localStorage.getItem('@meutempo/codigoEvento'));
    getModalidades();
 // getResultadoCompleto();
}, []);

useEffect(() => {
    
  getResultadoModalidade();

}, [codModalidade, codGenero]);

/*
useEffect(() => {
  getResultadoModalidade();
  const interval = setInterval(() => {
    getResultadoModalidade()
  }, 60000);
  return () =>clearInterval(interval);
}, [getResultadoModalidade])
*/
    return (
   
      <> 
      <Link style={{textDecoration: 'none'}} to="/" target="_self" rel="noreferrer">
      <FontAwesomeIcon icon={faHome}  style={{color:"#6DB65B"}}/><span style={{fontSize:15, color:'black', fontWeight:'bold' }}>&nbsp;Home</span>
      </Link>

      
 
     <div className="w-100" style={{
       width: "90%",
       margin: "0 auto",
       border: "0px solid #ccc",
       borderRadius: "10px",
       padding: "20px",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       marginTop: 0
      }}>
     
          <Container>
          <Row style={{ backgroundColor: "#cfffe5", borderRadius: "10px", color:'black' }}>
          <Col md={12}>
          <h1 style={{ backgroundColor: "#cfffe5", textAlign: "center", margin: "10px 0", fontSize: "24px" }}>
              {nomeEvento} 
             
            </h1>
           
            {dataHoraUltimaAtualizacao  && <>
             <h3 style={{ backgroundColor: "#cfffe5", textAlign: "center", margin: "10px 0", fontSize: "14px" }}>
                Última atualização: {(new Date(dataHoraUltimaAtualizacao)).toLocaleDateString("pt-BR") } às {(new Date(dataHoraUltimaAtualizacao)).toLocaleTimeString("pt-BR")} horas
                
                </h3>
                <FontAwesomeIcon icon={faArrowsRotate} size="lg" style={{color:"#6DB65B"}} onClick={updateAll} /><span style={{fontSize: "16px"}} onClick={updateAll}>&nbsp;Atualizar</span> 
                </>
            }
            <h2 style={{ backgroundColor: "#cfffe5", textAlign: "center", margin: "10px 0", fontSize: "20px", color: 'red' }}>
            Atenção! Resultado extra oficial.  
            </h2>
          </Col>
        </Row>
          <Row style={{ backgroundColor: "#cfffe5", margin: "5px",borderRadius: "10px", color:'black' }}>
         
          <Col md={8} style={{ margin: "5px"}}>
            <div style={{ marginBottom: "10px" }}>
            <select className="form-control" onChange={handleModalidadeChange}> 
            {
              (tipoEvento === "Corrida" || tipoEvento === "CorridaPorVolta")&& 
              <option value={codEvento}> -- Selecione a modalidade -- </option>
            }

            {       
              (tipoEvento === "MTB" || tipoEvento === "MTBPorVolta") && 
              <option value={codEvento}> -- Selecione a categoria -- </option>
            }
            
                 
            {
            eventos.length>0 && 
            eventos.map(
              (evt) => <option value={ evt.codigo + '|' + evt.modalidade + '|' + evt.genero}> {evt.modalidade != null ? (evt.modalidade) + ' - ' + (evt.genero === 'M' ? "Masculino" : "Feminino" ) :"Nenhuma modalidade encontrada" } </option>
              )
            }
            </select>        
           
            </div>
            {exibeCamposFiltro && <>
              <div style={{ marginBottom: "10px" }}>
              <input type="test"  className="form-control" value={textoBuscaNumero} placeholder="Busque pelo número" onChange={handleNumeroChange}></input>
              </div>
              <div style={{ marginBottom: "10px" }}>
              <input type="text" className="form-control" value={textoBuscaNome} placeholder="Busque pelo nome" onChange={(e) => setTextoBuscaNome(e.target.value)}></input>
                
              </div>

            </>}
             
          </Col>
        </Row>
       
    </Container>
          
            </div>

     
      {evento && resultados && resultados.map ((resultadoAtleta) => (
                <div className="w-100">
                  <Card className="h-auto text-dark">
                    <Row style={{backgroundColor: "#cfffe5"}}>
                    <Col xs={4} className="d-flex align-items-center justify-content-center">
                      <div className="rounded mx-1 d-flex align-items-center justify-content-center" style={{height: '80px', width: '50%', backgroundColor: '#ffd87d', margin:'5px ' }}>
                        <p style={{fontSize: '0.8rem', fontWeight: 'bold'}}>
                          
                          
                          {resultadoAtleta.colocacao_geral}
                          
                        <br/>
                        {(tipoEvento === "MTB" || tipoEvento === "MTBPorVolta"  ) && 
                          <span>Cl.</span>
                        }
                         {tipoEvento === "Corrida" && 
                          <span>Cl. Geral</span>
                        }
                         {tipoEvento === "CorridaPorVolta" && 
                          <span>Cl. Geral</span>
                        }
                        
                        </p>
                      
                      </div>
                      {tipoEvento === "Corrida" && 
                         <div className="rounded mx-1 d-flex align-items-center justify-content-center" style={{height: '80px', width: '50%',  backgroundColor: '#ffd87d'}}>
                         <p style={{fontSize: '0.8rem', fontWeight: 'bold'}}>{resultadoAtleta.colocacao_faixa ? resultadoAtleta.colocacao_faixa : "---"} <br/>Cl. Faixa</p>
                         </div>
                      }
                       {tipoEvento === "CorridaPorVolta" && 
                         <div className="rounded mx-1 d-flex align-items-center justify-content-center" style={{height: '80px', width: '50%',  backgroundColor: '#ffd87d'}}>
                         <p style={{fontSize: '0.8rem', fontWeight: 'bold'}}>{resultadoAtleta.colocacao_faixa ? resultadoAtleta.colocacao_faixa : "---"} <br/>Cl. Faixa</p>
                         </div>
                      }
                     
                    </Col>
                    <Col xs={8} className='rounded ' style={{fontSize: '1.0rem', backgroundColor: "#cfffe5"}}>
                        <Card.Body>
                          <Card.Title style={{fontSize: '1.0rem'}}>Nº {resultadoAtleta.numero_atleta}&nbsp;-&nbsp;{resultadoAtleta.atleta}</Card.Title>
                            
                          <Card.Text className="font-size-sm">
                            {tipoEvento === "Corrida" && 
                            <> 
                                  <FontAwesomeIcon icon={faUsersRectangle} /> {resultadoAtleta.faixa_etaria} &nbsp;&nbsp;&nbsp; 
                                <FontAwesomeIcon icon={faFlagCheckered} /> {resultadoAtleta.modalidade} &nbsp;&nbsp;&nbsp; <br/> 
                            </>
                            
                            }
                            {tipoEvento === "CorridaPorVolta" && 
                            <> 
                                  <FontAwesomeIcon icon={faUsersRectangle} /> {resultadoAtleta.faixa_etaria} &nbsp;&nbsp;&nbsp; 
                                <FontAwesomeIcon icon={faFlagCheckered} /> {resultadoAtleta.modalidade} &nbsp;&nbsp;&nbsp; <br/> 
                            </>
                            
                            }
                           {tipoEvento === "Corrida" && <>
                             <FontAwesomeIcon icon={faClock} />  {resultadoAtleta.tempo}  <br /><span style={{fontSize: 10, fontWeight:'bold', marginTop:0 }}>CRONOCHIP CRONOMETRAGEM</span>
                            </>
                           }
                           {tipoEvento === "CorridaPorVolta" && <>
                             <FontAwesomeIcon icon={faClock} />  {resultadoAtleta.tempo}  <span style={{ fontSize: 16, fontWeight: 'bold' }}>&nbsp;Lap</span><span style={{ fontSize: 5}}> &nbsp; </span><span style={{ fontSize: 16,}}> {resultadoAtleta.volta}   </span> <br /><span style={{fontSize: 10, fontWeight:'bold', marginTop:0 }}>CRONOCHIP CRONOMETRAGEM</span>
                            </>
                           }
                            {tipoEvento === "MTB" && <>
                             <FontAwesomeIcon icon={faClock} size="xl"/>&nbsp;&nbsp; <span style={{ fontSize: 18 }}>{resultadoAtleta.tempo}  </span> <br /><span style={{fontSize: 10, fontWeight:'bold', marginTop:0 }}>CRONOCHIP CRONOMETRAGEM</span>
                            </>
                           }
                            {tipoEvento === "MTBPorVolta" && <>
                             <FontAwesomeIcon icon={faClock} size="xl"/>&nbsp;&nbsp; <span style={{ fontSize: 18 }}>{resultadoAtleta.tempo}  </span>  <span style={{ fontSize: 18, fontWeight: 'bold' }}>&nbsp;Lap</span><span style={{ fontSize: 5}}> &nbsp; </span><span style={{ fontSize: 18,}}> {resultadoAtleta.volta}   </span>  <br /><span style={{fontSize: 10, fontWeight:'bold', marginTop:0 }}>CRONOCHIP CRONOMETRAGEM</span>
                            </>
                           }
                          </Card.Text>
                        </Card.Body>
                      </Col>
                     
                    </Row>
                   
                   
              </Card>
              
            </div>
              )
  
              )}
     
  </>
      );
}

export default Evento;